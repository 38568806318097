<template>
  <div>
    <div v-if="career_data.count > 0" class="content__title content__title--party content__title--start">
      <h2 class="title">Список мест работы</h2>
      <router-link
        :to="{ name: 'profile-career-create' }"
        class="link link--right"
      >
        <span>добавить</span>
      </router-link>
    </div>
    <div class="data-cards">
      <router-link v-if="career_data.count < 1" :to="{ name: 'profile-career-create' }" class="data-cards__item data-cards__item--empty">
        <div class="data-cards__title"><span>Место работы</span></div>
        <div class="data-cards__picture">
          <img src="@/assets/img/profile/work-def-icon.svg" alt="">
          <img src="@/assets/img/profile/work-color-icon.svg" alt="">
        </div>
        <div class="data-cards__text">
          На данный момент данные о вашей карьере не заполнены, нажмите сюда, чтобы заполнить
        </div>
      </router-link>
      <div v-else class="data-cards__item" :class="{ 'data-cards__item--main': item.is_main }" v-for="item in career_data.results" :key="item.id">
        <div class="data-cards__title">
          <span>{{ item.org_text }}</span>
          <router-link :to="{ name: 'profile-career-edit', params: { id: item.id } }">редактировать</router-link>
        </div>
        <div class="data-cards__picture">
          <img src="@/assets/img/profile/work-color-icon.svg" alt="">
        </div>
        <div class="data-cards__text"><b>Должность:</b>&nbsp; {{ item.position }}</div>
        <div class="data-cards__text">
          <b>Период работы:</b> {{ item.date_start }} - <span v-if="item.date_end">{{ item.date_end }}</span> <span v-if="!item.date_end">по н.в.</span>
        </div>
        <div v-if="item.is_main" class="data-cards__text data-cards__text--theme data-cards__text--double"><img src="@/assets/img/profile/check-theme-icon.svg" alt=""><b>Основное место работы</b></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileCareer',
  data() {
    return {
      career_data: [],
    }
  },
  mounted() {
    this.$store.dispatch('profile/career/GET_LIST')
      .then(response => {
        this.career_data = response.data
      })
  },
}
</script>
